import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
import LengthGuideRow from "../components/lengthGuideRow.js";
import "../components/main.css";
export const query = graphql`
  query {
    mattband: file(relativePath: { eq: "mattband-300x230.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 300) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
    smala: file(relativePath: { eq: "smala_skor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 80) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
    normala: file(relativePath: { eq: "normala_skor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 80) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
    breda: file(relativePath: { eq: "breda_skor.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 80) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "hur-långa-skosnören"
    }}>{`Hur Långa Skosnören?`}</h1>
    <p>{`Den perfekta längden på skosnören för just dina skor kan bero både på
typen av skosnören och snörningen. Det bästa sättet är att ta av skosnöret
på ena skon och mäta det. Det finns däremot tillfällen då detta inte är
möjligt, till exempel har du kanske hittat ett par gamla snygga skor som
saknar skosnören, eller så kanske de har gått av vilket gör att du inte
har möjlighet att mäta dem. För dessa tillfällen har vi tagit fram en grov
guide här nedanför, där du genom att räkna hålen på dina skor kan ta fram
hur långa sina skosnören ska vara.`}</p>
    <h2 {...{
      "id": "är-du-på-jakt-efter-långa-skosnören-och-vill-köpa-nya-online"
    }}>{`Är du på jakt efter långa skosnören och vill köpa nya online?`}</h2>
    <span className="info">
  Hoppa då över till vår sida{" "}
  <Link to="/kopa-skosnoren-online/langa-skosnoren/" mdxType="Link">
    {" "}
    <b>Köpa Skosnören Online - Långa Skosnören</b>
  </Link>{" "}
  , där har vi mängder med våra längsta skosnören. Du kan enkelt via vårt filter
  välja både längd och färg.
    </span> Vi har också flera andra undersidor med skosnören i alla former och typer.
    <p>{`Det kan vara värt att tänka på hur hårt du knyter dina skor, vet du med
dig om att du brukar knyta ovanligt hårt så kanske du ska ta lite kortare
snören än vad som visas nedan, och knyter du lite för löst så tar du lite
längre.`}</p>
    <h2 {...{
      "id": "hur-långa-skosnören-ska-man-köpa"
    }}>{`Hur långa skosnören ska man köpa?`}</h2>
    <p>{`Det går däremot att göra en uppskattning av hur långa skosnören bör vara,
genom att räkna antalet hål på dina skor. Denna uppskattning förutsätter
att snörningen är en traditionell korsad snörning. Nedan finner du en
tabell där du utifrån antalet hålpar (dvs har du två på varje sida, ska du
kolla på två hål i tabellen nedan) kan avgöra en bra längd för dina
skosnören. Glöm inte heller att kolla så att tjockleken på de nya
skosnörena inte är tjockare än hålen i dina skor.`}</p>
    <span className={"info"}>
Klicka på rätt längd i tabellen nedan så kommer du till alla skosnören i
den längden.
    </span>
    <table className={"styled"}>
      <thead>
        <tr>
          <th rowSpan="2">Antal hålpar</th>
          <th colSpan="3" className="length">
Längd
          </th>
        </tr>
        <tr>
          <th>
Smala skor (finskor)
            <Image fixed={props.data.smala.childImageSharp.fixed} alt="Smala skor. Källa: https://commons.wikimedia.org/wiki/File:Oxford_shoes_-_Introduction_and_History.jpg, licenserad under  Creative Commons Attribution 2.0 Generic" title="Smala skor" rowOne="" rowTwo="" mdxType="Image" />
          </th>
          <th>
Vanliga skor (sneakers)
            <Image fixed={props.data.normala.childImageSharp.fixed} alt="Vanliga skor. Källa: https://commons.wikimedia.org/wiki/File:Black_Converse_sneakers.JPG, licenserad under Creative Commons Attribution-Share Alike 3.0 Unported" title="Vanliga skor" rowOne="" rowTwo="" mdxType="Image" />
          </th>
          <th>
Breda skor (skateskor)
            <Image fixed={props.data.breda.childImageSharp.fixed} alt="Breda skor" title="Breda skor" rowOne="" rowTwo="" mdxType="Image" />
          </th>
        </tr>
      </thead>
      <tbody>
        <LengthGuideRow pairs={2} smal={60} medium={70} wide={80} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={3} smal={60} medium={80} wide={100} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={4} smal={70} medium={90} wide={110} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={5} smal={80} medium={100} wide={130} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={6} smal={80} medium={110} wide={140} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={7} smal={90} medium={120} wide={160} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={8} smal={90} medium={130} wide={170} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={9} smal={100} medium={140} wide={190} mdxType="LengthGuideRow"></LengthGuideRow>
        <LengthGuideRow pairs={10} smal={100} medium={150} wide={200} mdxType="LengthGuideRow"></LengthGuideRow>
      </tbody>
    </table>
    <p>{`Men längden är inte allt du måste tänka på när du köper nya. Visste du
tillexempel att platta håller knuten mycket bättre? Vill du inte köpa
långa skosnören online är det givetvis mycket enklare att köpa dem i
fysiska butiker där du kan känna på dem samt se om de passar just dina
skor. Ibland kan det även vara viktigt att stilen och färgen passar skorna
som de ska användas till. Detta kan försvåra då alla färger och stilar
inte finns i alla längder.`}</p>
    <Image fixed={props.data.mattband.childImageSharp.fixed} alt="Måttband som används för att mäta hur långa skosnören man ska köpa" title="Måttband som används för att mäta hur långa skosnören man ska köpa" rowOne="Källa: https://commons.wikimedia.org/wiki/File:Soft_ruler.jpg" rowTwo="" mdxType="Image" />
    <h2 {...{
      "id": "olika-typer-av-skor"
    }}>{`Olika typer av skor`}</h2>
    <p>{`Som vi har sagt ovan så är tabellen enbart en approximation. Har du t.ex.
kostymskor så har man ofta ganska smalt mellan sidorna på skorna och man
ska då försöka hitta skosnören som är något kortare än vad guiden ovan
anger. Har du väldigt breda skor, t.ex. skejtar skor så bör du försöka
hitta skosnören som är något längre än vad guiden ovan anger. Denna guide
passar bäst för helt vanliga sneakers vilket också är den vanligaste typen
av skor i Sverige.`}</p>
    <h2 {...{
      "id": "extra-långa-skosnören"
    }}>{`Extra långa skosnören?`}</h2>
    <p>{`Är du ute efter ett par extra lång skosnören, t.ex. till ett par lite
högre skor eller kängor, så har vi samlat dessa under `}<Link to="/kopa-skosnoren-online/" mdxType="Link">{`Köpa Skosnören Online`}</Link>{`.`}</p>
    <p>{`Vill du ha en mer exakt guide så kan vi rekommendera `}<a href="https://www.fieggen.com/shoelace/shoelacecalc.htm">{`Ians Shoelaces`}</a>{` som har en formel där du mer exakt kan beräkna hur långa skosnören ska
vara för just dina skor. Att köpa nya i en fysisk butik, istället för
online, är givetvis det optimala då du väldigt enkelt både kan få hjälp
samt testa om de har rätt längd.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      